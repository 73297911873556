import React, { useCallback, useEffect, useRef, useState } from 'react'
import Dashboard from '../DashBoard'
import './documents.scss';
import useDetectOutsideClick from '../../CustomHooks/useDetectOutsideClick';
import { useNavigate, useParams } from 'react-router-dom';
import Auth from '../../Classes/Auth';
import SectionHeading from '../../CommanComponents/SectionHeading/SectionHeading';
import SearchControl from '../../CommanComponents/SearchControl/SearchControl';
import CommanButton from '../../CommanComponents/CommanButton/CommanButton';
import TopActionButtons from '../DashBoard/RightBarPannel/TopActionButtons/TopActionButtons';
import Storage from '../../Classes/Storage';
import { document_grid_View } from '../../Constant/auth'
import DocumentListView from './DocumentListView';
import { EmployeeDocumentAccepted, documentDefaultView } from '../../config';
import useDocuments from '../../CustomHooks/Documents/useDocuments';
import DocumentView from './DocumentView/DocumentView';
import useDocumentDetails from '../../CustomHooks/EmployeePersonalDetails/useDocumentDetails';

import { useDispatch, useSelector } from 'react-redux';
import PaginationSimple from '../../CommanComponents/PaginationSimple/PaginationSimple';
import Breadcrumb from '../../CommanComponents/Breadcrumb';
import BreadcrumbItem from '../../CommanComponents/Breadcrumb/BreadcrumbItem';
const Documents = () => {
    const { document_param, DocumentDetailsHandler, searchVal, data,
        handleSearch, handleResetSearch, pageNumber, setPageNumber, breadcrumb } = useDocuments()
    const { DocuemntEditBtnHandler } = useDocumentDetails()
    const documents = useSelector(e => e.documents)
    const dispatch = useDispatch()

    const [searchDocType, setSearchDocType] = useState('')
    const GridVal = Storage.alive(document_grid_View) ? Storage.getString(document_grid_View) : null
    const [gridView, setGridView] = useState(GridVal !== null ? GridVal : documentDefaultView)
    useEffect(() => {
        Storage.set(document_grid_View, gridView)
    }, [gridView])

    // Filter documents with at least one non-null document
    // const filteredDocuments = documents?.data.filter(
    //     val => val.front_document || val.back_document
    // );
    const filteredDocuments = documents?.data
    console.log(documents, "SAdfsdfghdfgb")
    return (
        <>
            <div className="document-main-wrapper">
                <div className="document-wrap">
                    <div className="heading-search-item justify-between align-center">
                        {/* <SectionHeading ClassName={" "}>
                            <p>{`Documents ${breadcrumb && "> " + breadcrumb}`} </p>
                        </SectionHeading> */}

                        <Breadcrumb >
                            <BreadcrumbItem title={"Documents"}
                                to={"/documents/all"}
                                className={`section-heading primary text-sm w-500 
                            ${Auth?.boolUserType() == 3 ? "quaternary" : (Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4) ? 'black' : "primary"}`}
                            />
                            {breadcrumb && <p
                                className={`section-heading primary text-sm w-500 
                                          ${Auth?.boolUserType() == 3 ? "quaternary" : (Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4) ? 'black' : "primary"}`}

                            >
                                &nbsp;{`>`} &nbsp;</p>}
                            <BreadcrumbItem title={breadcrumb}
                                disabled={true}
                                className={`section-heading primary text-sm w-500 
                            ${Auth?.boolUserType() == 3 ? "quaternary" : (Auth?.boolUserType() == 2 || Auth?.boolUserType() == 4) ? 'black' : "primary"}`}
                            />

                        </Breadcrumb>


                        <div className='d-flex align-center justify-center search-page-wrap '>
                            <SearchControl
                                value={searchVal}
                                placeholder={'Search for name, document type'}
                                onChange={(e) => handleSearch(e)}
                                reset={() => handleResetSearch()}
                                classNameWrappper={'employees-search'}
                            />
                        </div>

                        {/* <TopActionButtons showUploadDoc={true} />/ */}
                        <div className="view-changer-wrapper">
                            <div className="view-change-wrap">
                                <button className={`transparent  icons-wrap d-flex align-center justify-center  ${gridView === 'list' && "active"}`} title='List view'
                                    onClick={() => setGridView('list')}
                                >
                                    <i className="icon list-view"></i>
                                </button>
                                <button className={`transparent  icons-wrap d-flex align-center justify-center ${gridView === "grid" && "active"} `} title='Grid view'
                                    onClick={() => setGridView('grid')}
                                >
                                    <i className="icon grid-view"></i>
                                </button>
                            </div>
                        </div>
                        {gridView === 'list' && documents?.data?.length ?
                            <PaginationSimple
                                className={"document-pagenation"}
                                data={filteredDocuments}
                                shows={filteredDocuments?.length < 20 ? filteredDocuments?.length : "20"}
                                pageNumber={pageNumber}
                                setPageNumber={setPageNumber}
                            />
                            : ''
                        }
                    </div>
                    <div className="folder-item-wrapper">
                        {/* {gridView === "grid" ? */}
                        <div className={`grid-folder-item-wrap ${gridView !== "grid" && 'active-list-view'}`} >
                            {data?.document_param == 'all' && gridView === "grid" && searchVal === '' ? documents.document_count?.map((val, key) => {
                                return (
                                    <div className="folder-item d-flex align-center justify-between relative" key={key} onClick={() => DocumentDetailsHandler(val?.document_name)} >
                                        <div className='d-flex align-center name-icon-wrap'>
                                            <i className="folder-icon"></i>
                                            <p className="text-xxs w-400 secondary folder-name" title={val?.document_name}> {val?.document_name}</p>
                                        </div>
                                        <div className="count-wrapper d-flex">
                                            <div className="item d-flex align-center justify-center pointer " >
                                                <i className='icons sT-icon secondary i-xxs document-icon'></i>
                                                <p className='text-xxs w-400 secondary'>{val.employee_count}</p>
                                            </div>
                                            <div className="item d-flex align-center justify-center pointer " >
                                                <i className='icons sT-icon secondary i-xxs profile-icon'></i>
                                                <p className='text-xxs w-400 secondary'>{val.no_passports ? val.no_passports : 0}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : <DocumentView
                                gridView={gridView}
                                document_param={data?.document_param}
                                DocuemntEditBtnHandler={DocuemntEditBtnHandler}
                                searchDocType={setSearchDocType}
                                searchVal={searchVal}
                                showsPerPage={documents?.data?.length < 20 ? documents?.data?.length : "20"}
                                pageNumber={pageNumber}
                            />
                            }
                        </div>
                        {/* // : */}
                        {data?.document_param == 'all' && gridView === "list" && searchVal === '' && <DocumentListView DocumentDetailsHandler={DocumentDetailsHandler} document_param={document_param} />}
                        {/* } */}
                    </div>


                </div>
            </div>
        </>
    )
}

export default Documents