import React, { useCallback, useEffect, useState } from 'react'
import './employeeDetail.scss'
import Dashboard from '../DashBoard'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import SectionHeading from '../../CommanComponents/SectionHeading/SectionHeading'
import PersonalDetails from './PersonalDetails/PersonalDetails'
import DocumentsDetails from './DocumentsDetails/DocumentsDetails'
import Tabs from '../../CommanComponents/Tabs/Tabs'
import CommanButton from '../../CommanComponents/CommanButton/CommanButton'
import useEmployeePersonalDetails from '../../CustomHooks/EmployeePersonalDetails/useEmployeePersonalDetails'
import Reminders from './Reminders/Reminders'
import { useDispatch } from 'react-redux'
import { setEmployeeType } from '../../store/Actions/EmployeeManagement'
import { deleteEmployee, setActiveTab } from '../../store/Actions/PersonalDetails'
import DeleteConfirmPop from '../../CommanComponents/DeleteConfirmPop/DeleteConfirmPop'
import Dropdown from '../../CommanComponents/Dropdown/Dropdown'
import useDocumentDetails from '../../CustomHooks/EmployeePersonalDetails/useDocumentDetails'
import { setDocType } from '../../store/Actions/DocumentUpload'
import DataNotFound from '../../CommanComponents/DataNotFound/DataNotFound'
import Auth from '../../Classes/Auth'

function EmployeeDetail() {
    const dispatch = useDispatch()
    const { activeEdit, editBtnHandler, setActiveEdit, handlePersonalDetailsBtn,
        dobValue, setDobValue, type, setType, joiningDate, setJoiningDate,
        employeeData, handleInput, handleGenderChange, handlePhoneInput, showError,
        formRef, resetEmployeeData, personalDetails, singleEmployee, employeeManagement, arrowBtnHandler,
        arrowBtn
    } = useEmployeePersonalDetails();

    const onActiveDocChange = (newActiveDoc) => {
        dispatch(setDocType(newActiveDoc)); // Update the activeDoc state in DocumentsDetails
    };

    const {
        activeDoc, DynamicInputValues, setDynamicInputValues, documentSaveBtn,
        onChangeFile, handleDelete, HandleDownload, handleInputChange,
        ondynamicDropdownChange, handleDocumentChnage, inputFile,
        inputFile2,
        imageRef2,
        fileName,
        fileName2, setFileName,
        setFileName2, EmployeeDocumentsList, imageRef, documentUpload, deleteHandler, DocuemntEditBtnHandler
        , handleDocumentCancelBtn, showDocError, PDFImg, PDFImg2, emiratesIDFileds, setEmiratesIDFileds
        , setPDFImg,
        setPDFImg2, regexError, setShowDocError, handleDateInput, CancleDeleteDoc, deletePopDoc, setDeletePopDoc,
        deleteDocType, setDeleteDocType, handleDeleteBtnPop } = useDocumentDetails(onActiveDocChange)

    const DropdownDisableList = documentUpload?.data?.map(doc => doc.Document_type) || []


    const [deletePop, setDeletePop] = useState(false)
    const [deleteId, setDeleteId] = useState("");
    const navigate = useNavigate()
    const location = useLocation()
    const newEmployeeHandleBtn = () => {
        navigate('/employees-management/add-new-employee')
        dispatch(setEmployeeType('new'))
        dispatch(setActiveTab("Personal"))
    }

    const handleActiveTab = useCallback((e) => {
        dispatch(setActiveTab(e))
        setShowDocError(false)
    }, [personalDetails?.activeTab])


    // DELETE EMPLOYEE HERE
    const deleteHandler1 = useCallback((id) => {
        dispatch(deleteEmployee(id))
    }, [personalDetails?.delete])

    const deleteClickHandler = (id) => {
        setDeleteId(id);
        setDeletePop(deletePop === id ? -1 : id);
    }
    const CancleDelete = () => {
        setDeleteId("");
        setDeletePop(false);
    }
    const handleDeleteBtn = () => {
        setDeleteId("");
        setDeletePop(false);
        deleteHandler1(deleteId)
    }
    const searchParams = new URLSearchParams(location.search);
    const client_id_param = searchParams.get('client_id')

    console.log(Auth.user(), "sdgsgh 1")

    // useEffect(() => {
    //     if (location?.pathname?.includes("/employees-management")) {
    //         // dispatch(setFetchType(''))
    //         // dispatch(resetClientList())
    //         dispatch(setActiveTab("Personal"))
    //     }
    // }, [])


    // useEffect(() => {

    //     if (location?.pathname?.includes("/employees-management")) {
    //         // dispatch(setFetchType(''))
    //         // dispatch(resetClientList())
    //         dispatch(setActiveTab("Personal"))
    //         console.log("gyudfdzf")
    //     }
    // }, [])
    console.log(singleEmployee?.last_name, "singleEmployee?.last_name")
    return (
        <>
            {
                // client_id_param !== "null" ?
                // !personalDetails?.single_employee?.data?.hasOwnProperty('message') ?
                <div className="employee-details-main-wrapper">
                    {/* <SectionHeading Text={"Employee Details"} ClassName={'mb-20'} /> */}
                    <div className="heading-search-item  align-center wrap justify-between ">
                        <SectionHeading  >
                            {/* {type === 'new' ? "Add New Employee" : type === 'old' ? "Employee Details" : ""} */}
                            {employeeManagement.employee_type === 'new' || type === 'new' ? "Add New Employee" : ""}

                            {(employeeManagement.employee_type === 'old' || type === 'old') &&
                                singleEmployee?.first_name && singleEmployee?.first_name + ' '}

                            {/* {(employeeManagement.employee_type === 'old' || type === 'old') &&
                                singleEmployee?.last_name !== undefined && singleEmployee?.last_name !== null && singleEmployee?.last_name !== "undefined"
                                ? singleEmployee?.last_name : ""} */}
                            {(employeeManagement.employee_type === 'old' || type === 'old') &&
                                singleEmployee.last_name !== "undefined" && singleEmployee?.last_name !== undefined && singleEmployee.last_name !== null && singleEmployee.last_name !== "null" ? singleEmployee.last_name : ""}

                        </SectionHeading>
                        {/* {(employeeManagement.employee_type === 'old' || type === 'old') &&
                        <div className="actions-wrappper">
                            <CommanButton onClick={() => newEmployeeHandleBtn()} ClassName={"action-btn"} >
                                <p className='w-700'><span className='text-xs'>  + </span>Employee</p>
                            </CommanButton>
                        </div>
                    } */}

                        <div className="employee-details-tabs-main-wrapper  ">
                            <Tabs
                                disableBtns={employeeManagement.employee_type === 'new' || type === 'new' ? ['active', 'Reminders', 'Personal'] : []}
                                tabs={['Documents', Auth?.boolUserType() !== 5 ? 'Reminders' : "", 'Personal'].filter(Boolean)}
                                defaultValue={personalDetails?.activeTab}
                                activeTabHandle={(e) => handleActiveTab(e)}
                                TabButtonClass={"default-tab text-xxs w-700"}
                                ActiveTabClass={"active"}
                                TabWrapperClass={"setting-tabs-wrapper"}
                            />
                        </div>
                        <div className="employee-top-wrapper d-flex justify-between   align-center">
                            {/* <p className="employee-name secondary text-xs w-700 ">
                    {
                        (employeeManagement.employee_type === 'old' || type === 'old') && singleEmployee?.first_name ? (
                            singleEmployee.last_name ? `${singleEmployee.first_name} ${singleEmployee.last_name}` : "Employee Full Name"
                        ) : null
                    }


                </p> */}

                            {personalDetails?.activeTab === "Personal" ? <div className="employee-top-actions-wrap d-flex ">
                                {(employeeManagement.employee_type === 'old' || type === 'old') ?
                                    <>
                                        <button className="icons-wrap transparent d-flex align-center justify-center" onClick={editBtnHandler}> <i className="icons edit-icon"></i></button>
                                        {Auth?.boolUserType() !== 3 && Auth?.boolUserType() !== 5 && <>
                                            <button className="icons-wrap transparent d-flex align-center justify-center" onClick={() => deleteClickHandler(singleEmployee?.employee_id)} > <i className="icons delete-icon"></i></button>
                                            <button className={`icons-wrap transparent d-flex align-center justify-center`} disabled={arrowBtn?.prev} onClick={() => arrowBtnHandler('prev')} > <i className="icons left-icon"></i></button>
                                            <button className={`icons-wrap transparent d-flex align-center justify-center `} disabled={arrowBtn?.next} onClick={() => arrowBtnHandler('next')}> <i className="icons right-icon"></i></button>

                                        </>}
                                    </> : ""
                                }
                                {Auth?.boolUserType() !== 3 && Auth?.boolUserType() !== 5 && <button onClick={() => navigate('/employees-management')} className="icons-wrap transparent d-flex align-center justify-center"> <i className="icons cross-icon"></i></button>}
                            </div>
                                :
                                personalDetails?.activeTab === "Documents" ?
                                    <div className="employee-document-dropdown-wrap">
                                        <Dropdown
                                            key={activeDoc} // Change key prop to activeDoc
                                            defaultValue={activeDoc}
                                            data={EmployeeDocumentsList}
                                            clickHandle={(val) => handleDocumentChnage(val)}
                                            padding={"8px 12px"}
                                            ClassName={'employee-document-dropdown'}
                                            btnClassName={"document-dropdown-btn"}
                                            width={'100%'}
                                            borderWidth={'1px'}
                                            disableList={[...DropdownDisableList, 'Visa', 'Medical insurance', 'Driving license', 'Family documents', 'Others']}
                                        />
                                    </div>
                                    : ""
                            }
                        </div>
                    </div>
                    <div className="setting-content-wrap">
                        {personalDetails?.activeTab === "Personal" ? <PersonalDetails handleActiveTab={handleActiveTab} activeEditLocal={activeEdit} setActiveEditLocal={setActiveEdit} /> :
                            personalDetails?.activeTab === "Documents" ?
                                <DocumentsDetails
                                    activeDoc={activeDoc} DynamicInputValues={DynamicInputValues} setDynamicInputValues={setDynamicInputValues} documentSaveBtn={documentSaveBtn}
                                    onChangeFile={onChangeFile} handleDelete={handleDelete} HandleDownload={HandleDownload} handleInputChange={handleInputChange}
                                    ondynamicDropdownChange={ondynamicDropdownChange} handleDocumentChnage={handleDocumentChnage} inputFile={inputFile}
                                    inputFile2={inputFile2}
                                    imageRef2={imageRef2}
                                    fileName={fileName}
                                    fileName2={fileName2} setFileName={setFileName}
                                    setFileName2={setFileName2}
                                    EmployeeDocumentsList={EmployeeDocumentsList}
                                    imageRef={imageRef} documentUpload={documentUpload}
                                    deleteHandler={deleteHandler}
                                    DocuemntEditBtnHandler={DocuemntEditBtnHandler}
                                    handleDocumentCancelBtn={handleDocumentCancelBtn}
                                    showDocError={showDocError} PDFImg={PDFImg} PDFImg2={PDFImg2}
                                    emiratesIDFileds={emiratesIDFileds}
                                    setEmiratesIDFileds={setEmiratesIDFileds}
                                    setPDFImg={setPDFImg}
                                    setPDFImg2={setPDFImg2}
                                    regexError={regexError}
                                    handleDateInput={handleDateInput}
                                    CancleDeleteDoc={CancleDeleteDoc}
                                    deletePopDoc={deletePopDoc}
                                    setDeletePopDoc={setDeletePopDoc}
                                    deleteDocType={deleteDocType}
                                    setDeleteDocType={setDeleteDocType}
                                    handleDeleteBtnPop={handleDeleteBtnPop}
                                />
                                :
                                personalDetails?.activeTab === "Reminders" ? <Reminders handleActiveTab={handleActiveTab} /> : ""
                        }
                    </div>
                    {deletePop && <DeleteConfirmPop
                        handleCancelVar={CancleDelete}
                        deleteVarHandler={() => handleDeleteBtn()}
                    />}
                </div>
                // : <DataNotFound />
            }
        </>
    )
}

export default EmployeeDetail