import DocumentsAction from "../Actions/Documents/actionType"
const initState = {
    data: [],
    document_count: []
}

const documents = (state = initState, { type, payload }) => {
    switch (type) {
        case DocumentsAction.SET_PASSPORT_SUCCESS: return {
            ...state,
            data: payload
        }
        case DocumentsAction.RESET_PASSPORT_SUCCESS: return {
            ...state,
            data: payload
        }
        case DocumentsAction.SET_SEARCH: return {
            ...state,
            data: payload
        }
        case DocumentsAction.SET_DOCUMENTS_COUNT: return {
            ...state,
            document_count: payload
        }

        default: return state
    }
}

export default documents