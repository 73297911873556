// import {
//     login,
//     userDetail,
//     registerDetails,
//     userType,
//     noty_count
// } from "../Constant/auth";
// import Cookie from "./Cookies";
// import Storage from "./Storage";
// import SessionStorage from "./SessionStorage";

// class Auth {
//     isLogin() {
//         return (
//             // Cookie.alive(login)
//             Storage.alive(login)
//         )
//     }

//     setUserLogin(loggedInUserData) {
//         var UserData;
//         UserData = {
//             countryName: loggedInUserData?.business,
//             // token: loggedInUserData?.access,
//             user_id: loggedInUserData?.id,
//             user_type: loggedInUserData?.user_type,
//             business_name: loggedInUserData?.business_name,
//             email: loggedInUserData?.email,
//             phone_no: loggedInUserData?.phone_no,
//             business_licence_no: loggedInUserData?.business_licence_no,
//             Emirates_ID: loggedInUserData?.Emirates_ID,
//             first_name: loggedInUserData?.first_name,
//             last_name: loggedInUserData?.last_name,
//             Nationality: loggedInUserData?.Nationality,
//             text_id: loggedInUserData?.text_id,
//             text_id: loggedInUserData?.text_id,
//             dob: loggedInUserData?.dob,
//             gender: loggedInUserData?.gender,
//             address: loggedInUserData?.address,
//             country: loggedInUserData?.country,
//             state: loggedInUserData?.state,
//             city: loggedInUserData?.city,
//             full_name: loggedInUserData?.full_name,
//             user_id_user: loggedInUserData?.added_by_user_id,
//             employee_id: loggedInUserData?.employee_id,

//         };
//         Cookie.set(userDetail, UserData, Cookie.cokConfig.type.json);
//         this.setLogin();
//     }

//     userType() {
//         // return Storage.getString(userType)
//         let bool_user_type;
//         if (!this.isLogin()) {
//             bool_user_type = Storage.getBool(userType)
//         } else {
//             bool_user_type = this.user().user_type
//         }
//         let user_type;
//         if (bool_user_type == "1") {
//             user_type = "Business Owner"
//         } else if (bool_user_type == "2") {
//             user_type = "Typing Center"
//         } else if (bool_user_type == "3") {
//             user_type = "Individual"
//         } else if (bool_user_type == "4") {
//             user_type = "Typing Center User"
//         }
//         return user_type
//     }

//     boolUserType() {
//         if (this.userType() == "Business Owner") {
//             return 1
//         } else if (this.userType() == "Typing Center") {
//             return 2
//         } else if (this.userType() == "Individual") {
//             return 3
//         } else if (this.userType() == "Typing Center User") {
//             return 4
//         }
//     }


//     setLogin() {
//         Storage.setBool(login, true);
//         Cookie.set(login, true, Cookie.cokConfig.type.bool);
//     }

//     user() {
//         return Cookie.has(userDetail)
//             ? Cookie.get(userDetail, Cookie.cokConfig.type.json)
//             : {};
//     }

//     logout() {
//         Cookie.remove(login);
//         Cookie.remove(userDetail);


//         // Storage.remove(userDetail);
//         SessionStorage.remove(registerDetails);
//         Storage.remove(login)
//         Storage.remove(noty_count)

//     }

//     token() {
//         return this.user().token ? this.user().token : "";
//     }

//     hash() {
//         return this.user().hash ? this.user().hash : "";
//     }


//     reLogin() {
//         if (Cookie.has(userDetail)) {
//             //Storage.setJson(userDetail,Cookie.get(userDetail, Cookie.cokConfig.type.json));
//             //Storage.setBool(login, true);
//         }
//     }

//     updateUserDetail(userProperty, userPropertyValue) {
//         let authUserDetail = Cookie.get(userDetail, Cookie.cokConfig.type.json);
//         let userDetailToUpdate = {
//             ...authUserDetail,
//             [userProperty]: userPropertyValue,
//         };

//         Cookie.update(userDetail, userDetailToUpdate, Cookie.cokConfig.type.json);
//     }

// }

// export default new Auth();


import {
    login,
    userDetail,
    registerDetails,
    userType,
    noty_count
} from "../Constant/auth";
import Cookie from "./Cookies";
import Storage from "./Storage";
import SessionStorage from "./SessionStorage";

class Auth {
    isLogin() {
        return (
            // Cookie.alive(login)
            Storage.alive(login)
        )
    }

    setUserLogin(loggedInUserData) {
        var UserData;
        UserData = {
            countryName: loggedInUserData?.business,
            // token: loggedInUserData?.access, this value getting from login function
            user_id: loggedInUserData?.id,
            user_type: loggedInUserData?.user_type,
            // user_type: 5,
            business_name: loggedInUserData?.business_name,
            email: loggedInUserData?.email,
            phone_no: loggedInUserData?.phone_no,
            business_licence_no: loggedInUserData?.business_licence_no,
            Emirates_ID: loggedInUserData?.Emirates_ID,
            first_name: loggedInUserData?.first_name,
            last_name: loggedInUserData?.last_name,
            Nationality: loggedInUserData?.Nationality,
            text_id: loggedInUserData?.text_id,
            text_id: loggedInUserData?.text_id,
            dob: loggedInUserData?.dob,
            gender: loggedInUserData?.gender,
            address: loggedInUserData?.address,
            country: loggedInUserData?.country,
            state: loggedInUserData?.state,
            city: loggedInUserData?.city,
            full_name: loggedInUserData?.full_name,
            user_id_user: loggedInUserData?.added_by_user_id,
            employee_id: loggedInUserData?.employee_id,
            fist_login: loggedInUserData?.fist_login,
            Ac_no: loggedInUserData?.["A/c No"],

        };
        // Cookie.set(userDetail, UserData, Cookie.cokConfig.type.json);
        Storage.setJson(userDetail, UserData);
        this.setLogin();
    }

    userType() {
        // return Storage.getString(userType)
        let bool_user_type;
        if (!this.isLogin()) {
            bool_user_type = Storage.getBool(userType)
        } else {
            bool_user_type = this.user().user_type
        }
        let user_type;
        if (bool_user_type == "1") {
            user_type = "Business Owner"
        } else if (bool_user_type == "2") {
            user_type = "Typing Center"
        } else if (bool_user_type == "3") {
            user_type = "Individual"
        } else if (bool_user_type == "4") {
            user_type = "Typing Center User"
        }
        else if (bool_user_type == "5") {
            user_type = "employee_user"
        }
        return user_type
    }

    boolUserType() {
        if (this.userType() == "Business Owner") {
            return 1
        } else if (this.userType() == "Typing Center") {
            return 2
        } else if (this.userType() == "Individual") {
            return 3
        } else if (this.userType() == "Typing Center User") {
            return 4
        }
        else if (this.userType() == "employee_user") {
            return 5
        }
    }


    setLogin() {
        Storage.setBool(login, true);
        // Cookie.set(login, true, Cookie.cokConfig.type.bool);
    }

    user() {
        return Storage.alive(userDetail)
            ? Storage.getJson(userDetail)
            : {};
    }

    logout() {
        Cookie.remove(login);
        Cookie.remove(userDetail);
        Storage.remove(login);
        Storage.remove(userDetail);

        // Storage.remove(userDetail);
        SessionStorage.remove(registerDetails);
        Storage.remove(login)
        Storage.remove(noty_count)

    }

    token() {
        return this.user().token ? this.user().token : "";
    }

    hash() {
        return this.user().hash ? this.user().hash : "";
    }


    reLogin() {
        if (Cookie.has(userDetail)) {
            //Storage.setJson(userDetail,Cookie.get(userDetail, Cookie.cokConfig.type.json));
            //Storage.setBool(login, true);
        }
    }

    updateUserDetail(userProperty, userPropertyValue) {
        let authUserDetail = Storage.getJson(userDetail);
        let userDetailToUpdate = {
            ...authUserDetail,
            [userProperty]: userPropertyValue,
        };

        Storage.update(userDetail, userDetailToUpdate, Storage.stoConfig.type.json);
    }

}

export default new Auth();
