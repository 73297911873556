import React from 'react'
import { useDispatch } from 'react-redux';
import { dateFormat } from '../../../CustomHooks/dateFormat';
import DataNotFound from '../../../CommanComponents/DataNotFound/DataNotFound';
import useReminderSettings from '../../../CustomHooks/Settings/useReminderSettings';
import "./ReminderSetting.scss"
import CommanButton from '../../../CommanComponents/CommanButton/CommanButton';

const ReminderSetting = () => {
    const dispatch = useDispatch()
    const { reminders, handleInputChange, date, todayDate } = useReminderSettings();

    const calculateDaysDifference = (expiryDate) => {
        const expiry = new Date(expiryDate);
        const today = new Date(todayDate);
        const timeDifference = expiry.getTime() - today.getTime();
        const dayDifference = timeDifference / (1000 * 3600 * 24);
        return dayDifference;
    };
    return (
        <div className="settingreminder-main-wrapper">
            {/* <div className="reminder-top-wrap d-flex justify-between mb-15 align-center">
                <p className="employee-name secondary text-xs w-700 ">
                    {employeeManagement.employee_type !== 'new' && personalDetails?.single_employee?.data?.first_name + ' ' + personalDetails?.single_employee?.data?.last_name}
                </p>
            </div> */}
            {/* {reminders?.data.length ? */}
            <div className="reminder-content-wrapper">
                <div className="table-wrap">
                    <table className="table-item">
                        <thead className='table-head'>
                            <tr className="table-row">
                                <th className='text-xxs w-600 secondary' >{`Reminder 1 (Days)`}</th>
                                <th className='text-xxs w-600 secondary' >{`Reminder 2 (Days)`}</th>
                                <th className='text-xxs w-600 secondary' >{`Reminder 3 (Hours)`}</th>
                            </tr>
                        </thead>

                        <tbody className='table-body'>
                            <tr className="table-row" >
                                <td className='reminder1' >
                                    <input
                                        type='number'
                                        value={60}
                                        min={1}
                                        max={99}
                                    />
                                </td>
                                <td className='reminder1' >
                                    <input
                                        type='number'
                                        value={30}
                                        min={1}
                                        max={99}
                                    />
                                </td>
                                <td className='reminder1' >
                                    <input
                                        type='number'
                                        value={48}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="save-btn-wrap d-flex mt-60 ">
                        <CommanButton Text={'Save'} ClassName={'save-btn'} />
                    </div>
                </div>
            </div>

            {/* <DataNotFound />} */}
        </div>
    )
}

export default ReminderSetting