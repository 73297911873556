import documentsReq from './documentsReq'
import DocumentsAction from './actionType'
import { showSuccessToast } from '../SuccessMessagePopup'
const getAllPassportDocs = (doc_type, userId) => {
    return async (dispatch) => {
        documentsReq.get(`${documentsReq.endpoint.passport_documents.replace('__DOC__TYPE', doc_type).replace('__USER_ID__', userId)}`, (success) => {
            let payload = success.data?.map((item, index, array) => {
                return array[array.length - 1 - index];
            });
            dispatch({ type: DocumentsAction.SET_PASSPORT_SUCCESS, payload: payload })
        },
            (error) => {

            }
        )
    }
}

const resetGetDocs = () => {
    return { type: DocumentsAction.RESET_PASSPORT_SUCCESS, payload: [] }
}

const docSearch = (userId, value) => {
    return async (dispatch) => {
        documentsReq.get(documentsReq.endpoint.documents_search.replace('__USER_ID__', userId).replace('__VALUE__', value), (success) => {
            let payload = success.data?.map((item, index, array) => {
                return array[array.length - 1 - index];
            });
            dispatch(
                { type: DocumentsAction.SET_SEARCH, payload: payload },
            )
        }, (error) => {

        }
        )
    }
}
const getDocumentCount = (userId, client_id) => {
    return async (dispatch) => {
        documentsReq.get(documentsReq.endpoint.get_list_of_documents.replace('__USER_ID__', userId).replace('__CLIENT_ID__', client_id), (success) => {
            dispatch({ type: DocumentsAction.SET_DOCUMENTS_COUNT, payload: success?.data },)
        }, (error) => {

        }
        )
    }
}





const resetDocSearch = (doc_type, userId) => {
    return async (dispatch) => {
        dispatch(getAllPassportDocs(doc_type, userId));
    }
}
export { getAllPassportDocs, resetGetDocs, docSearch, resetDocSearch, getDocumentCount }