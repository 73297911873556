import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getReminderList, setRemider2 } from '../../store/Actions/Reminders'
import { useParams } from 'react-router-dom'
import { useTodayDate } from '../useTodayDate'

const useReminderSettings = () => {
    // const reminders = useSelector((state) => state.reminders)
    const reminders = {
        data: [
            {
                "Document_type": "Passport",
                "employee_id": 9,
                "Date_of_Expiry": "2024-09-24",
                "Reminder_1": "2024-07-26",
                "Reminder_3": "2024-09-22",
                "Reminder_2": "2024-08-25",
                "created_date": "2024-09-16T06:08:51.870808Z"
            },
            {
                "Document_type": "Emirates ID",
                "employee_id": 9,
                "Date_of_Expiry": "2024-10-02",
                "Reminder_1": "2024-08-03",
                "Reminder_3": "2024-09-30",
                "Reminder_2": "2024-09-16",
                "created_date": "2024-09-16T07:26:14.121983Z"
            }
        ]
    }
    const dispatch = useDispatch()
    const { employee_ID } = useParams()
    // const get_employee_id = employee_ID.includes("id=") && employee_ID.replace("id=", "")
    const [date, setDate] = useState(null)
    const todayDate = useTodayDate()

    const handleInputChange = (e, docType) => {
        const inputValue = e.target.value;
        // setDate(inputValue);
        const data = {
            "Reminder_2": inputValue
        }
        let param;
        if (docType === "Emirates ID") {
            param = 'Emirates_ID'
        } else if (docType === "Passport") {
            param = 'Passport'
        }
        // dispatch(setRemider2(get_employee_id, param, data))
    }

    useEffect(() => {
        // const data = employee_ID.includes("id=") && employee_ID.replace("id=", "")
        // dispatch(getReminderList(data))
    }, [])
    return { reminders, handleInputChange, date, todayDate }
}

export default useReminderSettings